<template>
  <div>
    <el-form
      :rules="rules"
      :model="addBranchesForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="title" label="Наименование филиала">
            <el-input v-model="addBranchesForm.title"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="email" label="Почта">
            <el-input v-model="addBranchesForm.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Фактический адрес нахождения подразделения</h4>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="region" label="Область">
            <el-select
              clearable
              @change="changeArea"
              class="searchPole"
              v-model="addBranchesForm.areaInfo"
              value-key="id"
              placeholder="Область"
            >
              <el-option
                v-for="item in areaNames.content"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="district" label="Район">
            <el-select
              clearable
              @change="changeDistrict"
              :disabled="addBranchesForm.areaInfo === null"
              class="searchPole"
              v-model="addBranchesForm.districtInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchDistrictNames"
            >
              <el-option
                v-for="item in districtNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="locality" label="Населенный пункт">
            <el-select
              clearable
              :disabled="addBranchesForm.districtInfo === null"
              class="searchPole"
              v-model="addBranchesForm.cityInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Населенный пункт"
              :remote-method="searchCitiesNames"
            >
              <el-option
                v-for="item in citiesNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="street" label="Улица">
            <el-input v-model="addBranchesForm.street"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="phoneNumber" label="Телефон">
            <el-input v-model="addBranchesForm.phoneNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Добавить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "branchesAdd",
  mixins: [notification],
  data() {
    return {
      addBranchesForm: {
        areaId:null,
        districtId: null,
        cityInfo: null,
        districtInfo: {id:-1},
        areaInfo: {id:-1},
        street: null,
        phoneNumber: null,
        email: null,
        title: null,
        type: 1,
      },
      rules: {
        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        cityInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        districtInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        areaInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        responsiblePersonsInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      areaNames: "GETLISTALLAREAS",
      districtNames: "GETLISTDISTRICNAMES",
      citiesNames: "GETLISTCITIESNAMES",
    }),
  },

  methods: {
    searchDistrictNames(query) {
      this.getDistrictNames(query);
    },

    searchCitiesNames(query) {
      this.getCitiesNames(query);
    },

    changeArea() {
      this.searchDistrictNames("");
    },

    changeDistrict() {
      this.searchCitiesNames("");
    },

    save() {
      this.addBranchesForm.areaId = this.addBranchesForm.areaInfo.id != -1 && this.addBranchesForm.areaInfo !="" ? this.addBranchesForm.areaInfo.id : null;
      this.addBranchesForm.districtId = this.addBranchesForm.districtInfo.id!= -1 && this.addBranchesForm.districtInfo !="" ?  this.addBranchesForm.districtInfo.id : null;
      this.addBranchesForm.areaInfo = this.addBranchesForm.areaInfo !="" ? this.addBranchesForm.areaInfo : null;
      this.addBranchesForm.districtInfo = this.addBranchesForm.districtInfo !="" ? this.addBranchesForm.districtInfo : null;
      this.addBranchesForm.cityInfo = this.addBranchesForm.cityInfo !=""? this.addBranchesForm.cityInfo : {id:-1};
      this.addBranchesForm.street = this.addBranchesForm.street !="" ? this.addBranchesForm.street : null;
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store.dispatch("createUnit", this.addBranchesForm).then(() => {
            this.notification("Успешно", "Филиал добавлен", "success");
            this.$store.dispatch("getListUnits", {
              unitType: 1,
              size: 20,
              page: 0,
            });
            this.$store.dispatch("setShowModal", null);
          });
        }
      });
    },

    getListAllAreas() {
      this.$store.dispatch("getListAllAreas", {
        page: 0,
        size: 20,
      });
    },

    getDistrictNames(query) {
      this.$store.dispatch("getDistrictNames", {
        areaId: this.addBranchesForm.areaInfo.id,
        query: query,
      });
    },

    getCitiesNames(query) {
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.addBranchesForm.areaInfo.id,
        districtId: this.addBranchesForm.districtInfo.id,
        query: query,
      });
    },
  },

  mounted() {
    this.getListAllAreas();
  },
};
</script>

<style scoped></style>
