<template>
  <div>
    <branches-add></branches-add>
  </div>
</template>

<script>
import BranchesAdd from "@/components/ecologist/branches/branchesAdd";
export default {
  name: "BranchesAddView",
  components: { BranchesAdd },
};
</script>

<style scoped></style>
